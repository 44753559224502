export default () => {
  /**
   * # Stickyナビ
   */
  function stickyNav () {
    var $stickyNav = $('.nav-desktop-sticky');
    var $notHome = $('.not-home');
    var $staticNav = $('.main-header-wrapper');

    if ($notHome.length > 0) {
      $stickyNav.addClass('fixed-top d-md-block animated fadeInDown');
      return;
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() >= $staticNav.height()) {
        $stickyNav.addClass('fixed-top d-md-block animated fadeInDown');
      } else {
        $stickyNav.removeClass('fixed-top d-md-block');
      }
    });
  }

  /**
   * # スムーズスクロール
   */
  function smoothScroll () {
    // #main-concept
    // #main-service
    // #main-case-study
    // #main-access
    // #main-news-and-seminar
    //先頭に#がついたリンクがクリックされたら
    $('a[href^="#"]:not(a.noscroll)').click(function () {
      //スクロール速度 単位ミリ秒
      var speed = 300;
      // アンカーの値取得
      var href = $(this).attr('href');
      var target = $(href === '#' || href === '' ? 'html' : href);
      // 移動先を取得
      var position = target.offset().top - 100;
      $('html, body').animate({scrollTop: position}, speed, 'swing');
      return false;
    });
  }

  /**
   * # 要素が画面に現れたらクラスを追加して、アニメーションさせる関数 Animate.css仕様
   */
  function addClassViewElemOnWindow () {
    $(window).scroll(function () {
      $('.set-anim').not('.not-scroll-anim').each(function () {
        var flag = isScrolledIntoView($(this));
        if (flag === 'firstView') {
          // $(this).css({visibility: 'visible'}).removeClass($(this).attr('data-set-anim') + ' animated');
          // *ファーストビューなら何もしない
        } else if (flag === true) {
          $(this).css({visibility: 'visible'}).addClass($(this).attr('data-set-anim') + ' animated');
        } else if (flag === 'hidden') {
          $(this).css({visibility: 'hidden'}).removeClass($(this).attr('data-set-anim') + ' animated');
        }
      });
    });

    function isScrolledIntoView ($elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      var elemTop = $elem.offset().top;
      var elemBottom = elemTop + $elem.height();

      // ファーストインプレッション動作禁止
      if (docViewTop <= $(window).height()) {
        return 'firstView';
      }
      if ((elemTop <= docViewTop) || ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))) {
        return true;
      }
      if ((docViewBottom + $elem.height()) <= elemBottom) {
        return 'hidden';
      }
    }
  }

  /**
   * # 記事カテゴリ装飾 バッジ
   */
  function decoCategoryBadge () {
    const $cat = $('.post-cat a');
    $cat.addClass('badge badge-secondary');
  }

  /**
   * # 記事タグ装飾 バッジ
   */
  function decoTagBadge () {
    const $tags = $('.post-tags a');
    $tags.addClass('badge badge-info');
  }

  /**
   * # ブログサイドバーのリスト装飾 ボタン
   */
  function decoSidebarListBtn () {
    const $lists = $('.sidebar-list a');
    $lists.addClass('py-2 d-inline-block');
  }

  /**
   * # サイドバー
   */
  function sideBar () {
    // 初期で閉じる
    $('.sidebar.left').sidebar().trigger('sidebar:close');

    // 開く
    $('.navbar-toggler').on('click', function (e) {
      e.preventDefault();
      $('.sidebar.left').sidebar().trigger('sidebar:open');
    });

    // 閉じる
    $('.sidebar-close').on('click', function (e) {
      e.preventDefault();
      $('.sidebar.left').sidebar().trigger('sidebar:close');
    });

    // 開いたら
    $('.sidebar.left').on('sidebar:opened', function () {
      $(this).addClass('sidebar-boxshadow');
    });

    // 閉じたら
    $('.sidebar.left').on('sidebar:closed', function () {
      $(this).addClass('d-block');
      $(this).removeClass('sidebar-boxshadow');
    });
  }

  /**
   * # スライダー
   */
  function slider () {
    const $img = $('.main-header-image');
    if ($img.length === 0) {
      return;
    }
    let slideImagesFile = $img.attr('data-slider-images-file').split(',');
    let slideImagesPath = $img.attr('data-slider-images-path');
    let imgObj = [];
    slideImagesFile.forEach((val, idx) => {
      // プリロード
      imgObj[idx] = new Image();
      imgObj[idx].src = slideImagesPath + slideImagesFile[idx];
    });

    let idx = 0;
    const sliderExec = () => {
      $img.fadeOut(Number($img.attr('data-slider-duration')), function () {
        if (idx > (imgObj.length - 1)) {
          idx = 0;
        }
        $(this).attr('src', slideImagesPath + slideImagesFile[idx]);
        idx++;
        $(this).fadeIn(Number($img.attr('data-slider-duration')), setTimeoutFunc);
      });
    };
    const setTimeoutFunc = () => {
      setTimeout(sliderExec, Number($img.attr('data-slider-interval')));
    };
    setTimeoutFunc();
  }

  /**
   * # サイト毎の固有の処理
   */
  function siteExe () {
    // ヘッダータイトルの位置を調整
    const $mainHeaderTitle = $('.main-header-title');
    $mainHeaderTitle.css({
      marginTop: -Math.floor($mainHeaderTitle.height() / 2) + 'px'
    });
  }

  function init () {
    jQuery(function () {
      stickyNav();
      smoothScroll();
      addClassViewElemOnWindow();
      decoCategoryBadge();
      decoTagBadge();
      decoSidebarListBtn();
      sideBar();
      slider();

      // サイト固有の処理
      siteExe();
    });
  }

  init();
};
